
.new1{
  

}

.cardlimitheight{
  max-height: 250px;
  overflow: hidden;

}
.main{
  background-color: #f1f1f1;;
}
